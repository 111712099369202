<template>
  <div class="account-container">
    <!-- 修改密码 -->
    <a-form-model
      :model="passwordForm"
      ref="passwordForm"
      class="form"
      labelAlign="right"
      v-bind="layout"
      :rules="rules">
      <a-form-model-item style="text-align: left" label="注册邮箱">
        <!-- <span>{{ passwordForm.email }}</span> -->
        <a-input v-model.trim="passwordForm.email" disabled></a-input>
      </a-form-model-item>
      <a-form-model-item style="text-align: left" prop="password" label="密码">
        <a-input-password autocomplete="new-password" placeholder="请输入密码" v-model.trim="passwordForm.password" />
      </a-form-model-item>
      <a-form-model-item label="确认密码" style="text-align: left" prop="confirmPassword">
        <a-input-password autocomplete="new-password" placeholder="请输入确认密码" v-model.trim="passwordForm.confirmPassword" />
      </a-form-model-item>
      <a-form-model-item
        style="text-align: left"
        label="验证码"
        ref="code"
        prop="code"
        :rules="{
          required: true,
          message: '请填写验证码',
          trigger: 'blur'
        }"
      >
        <div style="display: flex">
          <a-input
            placeholder="请输入验证码"
            v-model.trim="passwordForm.code"
            @blur="
              () => {
                $refs.code.onFieldBlur()
              }
            "
          />
          <a-button
            style="margin-left: 10px;"
            class="m_btn_min primary"
            type="primary"
            :disabled="time !== 60"
            @click="handleCode"
          >{{ time == 60 ? '获取验证码' : `${time}s后再次获取` }}</a-button
          >
        </div>
      </a-form-model-item>
      <div class="btn">
        <a-button type="primary" @click="handleSubmit" >修改</a-button>
      </div>
    </a-form-model>
  </div>
</template>
<script>
import { verifyCode, resetPassword } from '@/apiForManage/system'
import { mapState } from 'vuex'
import { Encrypt } from '@/utils/jsencrypt'
export default {
  name: 'PasswordForm',
  computed: {
    ...mapState({
      name: (state) => state.user.name
    })
  },
  data () {
    const validatePass = (rule, value, callback) => {
      const { password } = this.passwordForm
      if (!value) {
        callback(new Error('请输入确认密码'))
      }
      if (value && password && value.trim() !== password.trim()) {
        callback(new Error('密码与确认密码不一致'))
      }
      callback()
    }
    const validatePwd = (rule, value, callback) => {
      if (value.length < 8) {
        callback(new Error('密码不能低于8位'))
      }
      callback()
    }
    return {
      passwordForm: {},
      timeId: undefined,
      time: 60,
      layout: {
        labelCol: { span: 5 },
        wrapperCol: { span: 16 }
      },
      rules: {
        password: [
          {
            required: true,
            validator: validatePwd,
            trigger: 'blur'
          }
        ],
        confirmPassword: [
          {
            required: true,
            trigger: 'blur',
            validator: validatePass
          }
        ],
        code: [
          {
            required: true,
            message: '请填写验证码',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  created () {
    this.passwordForm = { email: this.name }
  },
  methods: {
    // 获取验证码
    async verifyCodeFn (data) {
      const res = await verifyCode(data)
      if (res.code === 200) {
        this.timeId = setInterval(() => {
          this.time--
          if (this.time <= 0) {
            this.time = 60
            clearInterval(this.timeId)
            return false
          }
        }, 1000)
        this.$message.success('验证码发送成功')
        this.passwordForm = {
          ...this.passwordForm,
          code: res.data
        }
      }
    },
    // 修改密码
    async resetPasswordFn (data) {
      const res = await resetPassword(data)
      if (res.code === 200) {
        this.$message.success('修改成功')
        setTimeout(() => {
          this.$store.dispatch('Logout').then((res) => {
            this.$router.push('/user/login')
          })
        }, 500)
      }
    },
    handleCode () {
      const { email } = this.passwordForm
      this.verifyCodeFn(email)
    },
    resetPasswordForm () {
      this.passwordForm = { email: this.name }
    },
    handleSubmit () {
      this.$refs.passwordForm.validate((validate) => {
        if (!validate) return false
        const { password, confirmPassword } = this.passwordForm
        if (password !== confirmPassword) {
          return this.$message.error('密码与确认密码不一致')
        }
        this.resetPasswordFn({
          ...this.passwordForm,
          password: Encrypt(password),
          confirmPassword: Encrypt(confirmPassword)
        })
      })
    }
  }
}
</script>
<style lang="less" scoped>
.account-container {
  background-color: #fff;
  margin: 0 @mediumMargin @mediumMargin @mediumMargin;
  display: flex;
  justify-content: flex-start;
  border-radius: @mediumRadius;
  padding: @smallMargin 36px;
  box-shadow: @modelShadow;
  border: 1px solid @modelBorderColor;
  .form{
    width: 600px;
  }
  .btn{
    text-align: left;
    margin-top: 40px;
    button{
      width: 120px;
      margin-left: 36px;
    }
  }
}
.editBtn {
  ::v-deep .ant-form-item-label {
    label {
      display: none;
    }
  }
}
</style>
