var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"account-container"},[_c('a-form-model',_vm._b({ref:"passwordForm",staticClass:"form",attrs:{"model":_vm.passwordForm,"labelAlign":"right","rules":_vm.rules}},'a-form-model',_vm.layout,false),[_c('a-form-model-item',{staticStyle:{"text-align":"left"},attrs:{"label":"注册邮箱"}},[_c('a-input',{attrs:{"disabled":""},model:{value:(_vm.passwordForm.email),callback:function ($$v) {_vm.$set(_vm.passwordForm, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"passwordForm.email"}})],1),_c('a-form-model-item',{staticStyle:{"text-align":"left"},attrs:{"prop":"password","label":"密码"}},[_c('a-input-password',{attrs:{"autocomplete":"new-password","placeholder":"请输入密码"},model:{value:(_vm.passwordForm.password),callback:function ($$v) {_vm.$set(_vm.passwordForm, "password", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"passwordForm.password"}})],1),_c('a-form-model-item',{staticStyle:{"text-align":"left"},attrs:{"label":"确认密码","prop":"confirmPassword"}},[_c('a-input-password',{attrs:{"autocomplete":"new-password","placeholder":"请输入确认密码"},model:{value:(_vm.passwordForm.confirmPassword),callback:function ($$v) {_vm.$set(_vm.passwordForm, "confirmPassword", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"passwordForm.confirmPassword"}})],1),_c('a-form-model-item',{ref:"code",staticStyle:{"text-align":"left"},attrs:{"label":"验证码","prop":"code","rules":{
        required: true,
        message: '请填写验证码',
        trigger: 'blur'
      }}},[_c('div',{staticStyle:{"display":"flex"}},[_c('a-input',{attrs:{"placeholder":"请输入验证码"},on:{"blur":() => {
              _vm.$refs.code.onFieldBlur()
            }},model:{value:(_vm.passwordForm.code),callback:function ($$v) {_vm.$set(_vm.passwordForm, "code", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"passwordForm.code"}}),_c('a-button',{staticClass:"m_btn_min primary",staticStyle:{"margin-left":"10px"},attrs:{"type":"primary","disabled":_vm.time !== 60},on:{"click":_vm.handleCode}},[_vm._v(_vm._s(_vm.time == 60 ? '获取验证码' : `${_vm.time}s后再次获取`))])],1)]),_c('div',{staticClass:"btn"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v("修改")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }